import validate from "/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/node_modules/.pnpm/nuxt@3.11.2_@unocss+reset@0.59.4_eslint@8.57.0_floating-vue@5.2.2_rollup@4.14.3_stylelint@15._igd6tuutebwdsaofg7xwdtm6ii/node_modules/nuxt/dist/pages/runtime/validate.js";
import redirect_45trailing_45slash_45global from "/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.0.2_@nuxt+devtools@1.1.5_@unhead+shared@1.9.5_@vue+compiler-core_ccikk3boeha764rc6fkqzbv6xq/node_modules/@bttr-devs/nuxt-layer-base/middleware/redirect-trailing-slash.global.ts";
import _01_45maintenance_45global from "/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/middleware/01.maintenance.global.ts";
import _02_45active_45membership_45global from "/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/middleware/02.activeMembership.global.ts";
import manifest_45route_45rule from "/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/node_modules/.pnpm/nuxt@3.11.2_@unocss+reset@0.59.4_eslint@8.57.0_floating-vue@5.2.2_rollup@4.14.3_stylelint@15._igd6tuutebwdsaofg7xwdtm6ii/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  redirect_45trailing_45slash_45global,
  _01_45maintenance_45global,
  _02_45active_45membership_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "sofie-page": () => import("/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie@1.3.2_@nuxt+devtools@1.1.5_@unhead+shared@1.9.5_@vue+compiler-cor_65cheuu7sobyybebta76ho46ja/node_modules/@bttr-devs/nuxt-layer-sofie/middleware/sofie-page.ts"),
  employee: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/middleware/employee.ts"),
  manager: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/middleware/manager.ts"),
  member: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/middleware/member.ts"),
  page: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/middleware/page.ts"),
  partner: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/middleware/partner.ts"),
  auth: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/node_modules/.pnpm/@sidebase+nuxt-auth@0.7.2_next-auth@4.21.1_rollup@4.14.3/node_modules/@sidebase/nuxt-auth/dist/runtime/middleware/auth.mjs")
}