import revive_payload_client_xQhOnpeu6c from "/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/node_modules/.pnpm/nuxt@3.11.2_@unocss+reset@0.59.4_eslint@8.57.0_floating-vue@5.2.2_rollup@4.14.3_stylelint@15._igd6tuutebwdsaofg7xwdtm6ii/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_M8oIUo5zf4 from "/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/node_modules/.pnpm/nuxt@3.11.2_@unocss+reset@0.59.4_eslint@8.57.0_floating-vue@5.2.2_rollup@4.14.3_stylelint@15._igd6tuutebwdsaofg7xwdtm6ii/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_pVH57oSHTs from "/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/node_modules/.pnpm/nuxt@3.11.2_@unocss+reset@0.59.4_eslint@8.57.0_floating-vue@5.2.2_rollup@4.14.3_stylelint@15._igd6tuutebwdsaofg7xwdtm6ii/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_N4X5tDXrQP from "/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/node_modules/.pnpm/nuxt-site-config@2.2.12_@nuxt+devtools@1.1.5_@vue+compiler-core@3.4.23_nuxt@3.11.2_postcss@8._audmzutlj6cthj2wmspwan4akq/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import payload_client_hdBka1ibPV from "/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/node_modules/.pnpm/nuxt@3.11.2_@unocss+reset@0.59.4_eslint@8.57.0_floating-vue@5.2.2_rollup@4.14.3_stylelint@15._igd6tuutebwdsaofg7xwdtm6ii/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_ugMpAyoxif from "/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/node_modules/.pnpm/nuxt@3.11.2_@unocss+reset@0.59.4_eslint@8.57.0_floating-vue@5.2.2_rollup@4.14.3_stylelint@15._igd6tuutebwdsaofg7xwdtm6ii/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_x5SHtz05Cz from "/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/node_modules/.pnpm/nuxt@3.11.2_@unocss+reset@0.59.4_eslint@8.57.0_floating-vue@5.2.2_rollup@4.14.3_stylelint@15._igd6tuutebwdsaofg7xwdtm6ii/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_pnath29zKK from "/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/node_modules/.pnpm/nuxt-bugsnag@7.3.0_rollup@4.14.3/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import i18n_8CiQbI2O3a from "/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/node_modules/.pnpm/@nuxtjs+i18n@8.3.0_rollup@4.14.3_vue@3.4.23/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import formkitPlugin_pZqjah0RUG from "/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/.nuxt/formkitPlugin.mjs";
import plugin_FVpvnGv7Vc from "/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-module-apollo@1.1.1_react-dom@18.2.0_react@18.2.0_rollup@4.14.3_typescript@5.4.5_vue@3.4.23/node_modules/@bttr-devs/nuxt-module-apollo/dist/runtime/plugin.js";
import plugin_VoKm5K4wT5 from "/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/node_modules/.pnpm/@sidebase+nuxt-auth@0.7.2_next-auth@4.21.1_rollup@4.14.3/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import floating_vue_EIcJ7xiw0h from "/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/.nuxt/floating-vue.mjs";
import plugin_Adz2S1iscY from "/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_nuxt@3.11.2_rollup@4.14.3_vue@3.4.23/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import chunk_reload_client_x14MsKKhut from "/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/node_modules/.pnpm/nuxt@3.11.2_@unocss+reset@0.59.4_eslint@8.57.0_floating-vue@5.2.2_rollup@4.14.3_stylelint@15._igd6tuutebwdsaofg7xwdtm6ii/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import errors_9ittrw1r52 from "/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.0.2_@nuxt+devtools@1.1.5_@unhead+shared@1.9.5_@vue+compiler-core_ccikk3boeha764rc6fkqzbv6xq/node_modules/@bttr-devs/nuxt-layer-base/plugins/errors.ts";
import i18n_dhy1R4zoks from "/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.0.2_@nuxt+devtools@1.1.5_@unhead+shared@1.9.5_@vue+compiler-core_ccikk3boeha764rc6fkqzbv6xq/node_modules/@bttr-devs/nuxt-layer-base/plugins/i18n.ts";
import apollo_dTFbpTycwP from "/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie@1.3.2_@nuxt+devtools@1.1.5_@unhead+shared@1.9.5_@vue+compiler-cor_65cheuu7sobyybebta76ho46ja/node_modules/@bttr-devs/nuxt-layer-sofie/plugins/apollo.ts";
import sofie_required_version_zu4dqdTehM from "/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie@1.3.2_@nuxt+devtools@1.1.5_@unhead+shared@1.9.5_@vue+compiler-cor_65cheuu7sobyybebta76ho46ja/node_modules/@bttr-devs/nuxt-layer-sofie/plugins/sofie-required-version.ts";
import apollo_R4mUqRlV4d from "/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie-auth@1.3.2_@nuxt+devtools@1.1.5_@unhead+shared@1.9.5_@vue+compile_ahpeqio72h6lpkhu73ihnlmk4a/node_modules/@bttr-devs/nuxt-layer-sofie-auth/plugins/apollo.ts";
import auth_BPv9Uu71l6 from "/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie-auth@1.3.2_@nuxt+devtools@1.1.5_@unhead+shared@1.9.5_@vue+compile_ahpeqio72h6lpkhu73ihnlmk4a/node_modules/@bttr-devs/nuxt-layer-sofie-auth/plugins/auth.ts";
import api_GFfDXud5Cr from "/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/plugins/api.ts";
import apollo_1ILFkzpYSr from "/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/plugins/apollo.ts";
import vue3_toastify_OGYNDsiW9E from "/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/plugins/vue3-toastify.ts";
export default [
  revive_payload_client_xQhOnpeu6c,
  unhead_M8oIUo5zf4,
  router_pVH57oSHTs,
  _0_siteConfig_N4X5tDXrQP,
  payload_client_hdBka1ibPV,
  check_outdated_build_client_ugMpAyoxif,
  components_plugin_KR1HBZs4kY,
  prefetch_client_x5SHtz05Cz,
  plugin_pnath29zKK,
  i18n_8CiQbI2O3a,
  formkitPlugin_pZqjah0RUG,
  plugin_FVpvnGv7Vc,
  plugin_VoKm5K4wT5,
  floating_vue_EIcJ7xiw0h,
  plugin_Adz2S1iscY,
  chunk_reload_client_x14MsKKhut,
  errors_9ittrw1r52,
  i18n_dhy1R4zoks,
  apollo_dTFbpTycwP,
  sofie_required_version_zu4dqdTehM,
  apollo_R4mUqRlV4d,
  auth_BPv9Uu71l6,
  api_GFfDXud5Cr,
  apollo_1ILFkzpYSr,
  vue3_toastify_OGYNDsiW9E
]