import { default as _91_46_46_46slug_93DlO6Hza0oTMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/[...slug].vue?macro=true";
import { default as adressenfPqo9cddifMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/account/bedrijfsgegevens/[company]/adressen.vue?macro=true";
import { default as indexEhzpvHO1hWMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/account/bedrijfsgegevens/[company]/index.vue?macro=true";
import { default as medewerkers5RV0vZzIdRMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/account/bedrijfsgegevens/[company]/medewerkers.vue?macro=true";
import { default as indextm0FivaPRbMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/account/bedrijfsgegevens/index.vue?macro=true";
import { default as indexIIUgm5QlBZMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/account/communicatievoorkeuren/index.vue?macro=true";
import { default as indexBjGtdOz64nMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/account/evenementen/index.vue?macro=true";
import { default as _91factuur_93XU2E0dpIFGMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/account/facturen/[factuur].vue?macro=true";
import { default as index8zW1QzlXAeMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/account/facturen/index.vue?macro=true";
import { default as indexjSWMgrBxG3Meta } from "/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/account/index.vue?macro=true";
import { default as indexmPVMD47iixMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/account/ledenvoordelen/index.vue?macro=true";
import { default as incassoW6fsjQtHpjMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/account/lidmaatschap/[lidmaatschap]/incasso.vue?macro=true";
import { default as indexoDpxQIuSL7Meta } from "/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/account/lidmaatschap/[lidmaatschap]/index.vue?macro=true";
import { default as index0B5ZbhN9eMMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/account/lidmaatschap/index.vue?macro=true";
import { default as nieuwsbriefvoorkeurenn6s52pwezLMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/account/nieuwsbriefvoorkeuren.vue?macro=true";
import { default as accountsJR7cNq2zMMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/account.vue?macro=true";
import { default as loginDoC8s7eoGZMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/auth/login.vue?macro=true";
import { default as _91token_93Himk3SoqZNMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/auth/wachtwoord-instellen/[token].vue?macro=true";
import { default as _91token_93UT7ggJAbW4Meta } from "/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/auth/wachtwoord-vergeten/[token].vue?macro=true";
import { default as indexgsm2b4DDyUMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/auth/wachtwoord-vergeten/index.vue?macro=true";
import { default as _91slug_930CO2jsJgUAMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/bestuurders/[slug].vue?macro=true";
import { default as bestuurdersacademieFFrxpww6jbMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/bestuurders/bestuurdersacademie.vue?macro=true";
import { default as dossiers_45documentengJ9krHYAK4Meta } from "/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/bestuurders/dossiers-documenten.vue?macro=true";
import { default as indexvhPZKUZqwhMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/bestuurders/index.vue?macro=true";
import { default as bestuurdersFXuF847FgCMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/bestuurders.vue?macro=true";
import { default as dashboardgZH5adsuxCMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/dashboard.vue?macro=true";
import { default as aanmelden4ST5AsBRaGMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/evenementen/[slug]/aanmelden.vue?macro=true";
import { default as index7v0F4isNYaMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/evenementen/[slug]/index.vue?macro=true";
import { default as indexs8BXff73KEMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/evenementen/index.vue?macro=true";
import { default as evenementen0d7WBqv60EMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/evenementen.vue?macro=true";
import { default as index3QodGLAezDMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/index.vue?macro=true";
import { default as kennisbankpbfSTPGB32Meta } from "/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/kennisbank.vue?macro=true";
import { default as _91id_93A4xJaIcomBMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/klanten/[id].vue?macro=true";
import { default as indexAWJV5Shm8VMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/klanten/index.vue?macro=true";
import { default as afnemenm9ymMoPVMZMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/ledenvoordelen/[slug]/afnemen.vue?macro=true";
import { default as indexQ67XLr64nUMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/ledenvoordelen/[slug]/index.vue?macro=true";
import { default as indexgEhxOM47FjMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/ledenvoordelen/index.vue?macro=true";
import { default as ledenvoordelendG0aQyZf1nMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/ledenvoordelen.vue?macro=true";
import { default as indexaW81OkGjjcMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/lid-worden/index.vue?macro=true";
import { default as uitschrijvenB9O72LDJCyMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/nieuwsbrief/uitschrijven.vue?macro=true";
import { default as authorizeFdQkNoWIzcMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/oauth/authorize.vue?macro=true";
import { default as styles8CMSEP4B2HMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/styles.vue?macro=true";
import { default as _91id_93pMjUchKX2ZMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/verkoopkansen/[id].vue?macro=true";
import { default as indexXt69ed4WjoMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/verkoopkansen/index.vue?macro=true";
import { default as _91product_93FIoQC3vjlAMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/webshop/[category]/[product].vue?macro=true";
import { default as indexZooA0134GGMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/webshop/[category]/index.vue?macro=true";
import { default as cartfTWngYDExOMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/webshop/cart.vue?macro=true";
import { default as checkout6eou6RV1dtMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/webshop/checkout.vue?macro=true";
import { default as confirmationP1vsFCKG9kMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/webshop/confirmation.vue?macro=true";
import { default as indexX6ANzwotymMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/webshop/index.vue?macro=true";
import { default as webshop72RRcROG7JMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/webshop.vue?macro=true";
import { default as zoekenhqv1Q5LnugMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/zoeken.vue?macro=true";
import { default as indexD704OlaQYNMeta } from "~/pages/evenementen/[slug]/index.vue?macro=true";
export default [
  {
    name: _91_46_46_46slug_93DlO6Hza0oTMeta?.name ?? "slug",
    path: _91_46_46_46slug_93DlO6Hza0oTMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93DlO6Hza0oTMeta || {},
    alias: _91_46_46_46slug_93DlO6Hza0oTMeta?.alias || [],
    redirect: _91_46_46_46slug_93DlO6Hza0oTMeta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: accountsJR7cNq2zMMeta?.name ?? undefined,
    path: accountsJR7cNq2zMMeta?.path ?? "/account",
    meta: accountsJR7cNq2zMMeta || {},
    alias: accountsJR7cNq2zMMeta?.alias || [],
    redirect: accountsJR7cNq2zMMeta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/account.vue").then(m => m.default || m),
    children: [
  {
    name: adressenfPqo9cddifMeta?.name ?? "account-bedrijfsgegevens-company-adressen",
    path: adressenfPqo9cddifMeta?.path ?? "bedrijfsgegevens/:company()/adressen",
    meta: adressenfPqo9cddifMeta || {},
    alias: adressenfPqo9cddifMeta?.alias || [],
    redirect: adressenfPqo9cddifMeta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/account/bedrijfsgegevens/[company]/adressen.vue").then(m => m.default || m)
  },
  {
    name: indexEhzpvHO1hWMeta?.name ?? "account-bedrijfsgegevens-company",
    path: indexEhzpvHO1hWMeta?.path ?? "bedrijfsgegevens/:company()",
    meta: indexEhzpvHO1hWMeta || {},
    alias: indexEhzpvHO1hWMeta?.alias || [],
    redirect: indexEhzpvHO1hWMeta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/account/bedrijfsgegevens/[company]/index.vue").then(m => m.default || m)
  },
  {
    name: medewerkers5RV0vZzIdRMeta?.name ?? "account-bedrijfsgegevens-company-medewerkers",
    path: medewerkers5RV0vZzIdRMeta?.path ?? "bedrijfsgegevens/:company()/medewerkers",
    meta: medewerkers5RV0vZzIdRMeta || {},
    alias: medewerkers5RV0vZzIdRMeta?.alias || [],
    redirect: medewerkers5RV0vZzIdRMeta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/account/bedrijfsgegevens/[company]/medewerkers.vue").then(m => m.default || m)
  },
  {
    name: indextm0FivaPRbMeta?.name ?? "account-bedrijfsgegevens",
    path: indextm0FivaPRbMeta?.path ?? "bedrijfsgegevens",
    meta: indextm0FivaPRbMeta || {},
    alias: indextm0FivaPRbMeta?.alias || [],
    redirect: indextm0FivaPRbMeta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/account/bedrijfsgegevens/index.vue").then(m => m.default || m)
  },
  {
    name: indexIIUgm5QlBZMeta?.name ?? "account-communicatievoorkeuren",
    path: indexIIUgm5QlBZMeta?.path ?? "communicatievoorkeuren",
    meta: indexIIUgm5QlBZMeta || {},
    alias: indexIIUgm5QlBZMeta?.alias || [],
    redirect: indexIIUgm5QlBZMeta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/account/communicatievoorkeuren/index.vue").then(m => m.default || m)
  },
  {
    name: indexBjGtdOz64nMeta?.name ?? "account-evenementen",
    path: indexBjGtdOz64nMeta?.path ?? "evenementen",
    meta: indexBjGtdOz64nMeta || {},
    alias: indexBjGtdOz64nMeta?.alias || [],
    redirect: indexBjGtdOz64nMeta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/account/evenementen/index.vue").then(m => m.default || m)
  },
  {
    name: _91factuur_93XU2E0dpIFGMeta?.name ?? "account-facturen-factuur",
    path: _91factuur_93XU2E0dpIFGMeta?.path ?? "facturen/:factuur()",
    meta: _91factuur_93XU2E0dpIFGMeta || {},
    alias: _91factuur_93XU2E0dpIFGMeta?.alias || [],
    redirect: _91factuur_93XU2E0dpIFGMeta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/account/facturen/[factuur].vue").then(m => m.default || m)
  },
  {
    name: index8zW1QzlXAeMeta?.name ?? "account-facturen",
    path: index8zW1QzlXAeMeta?.path ?? "facturen",
    meta: index8zW1QzlXAeMeta || {},
    alias: index8zW1QzlXAeMeta?.alias || [],
    redirect: index8zW1QzlXAeMeta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/account/facturen/index.vue").then(m => m.default || m)
  },
  {
    name: indexjSWMgrBxG3Meta?.name ?? "account",
    path: indexjSWMgrBxG3Meta?.path ?? "",
    meta: indexjSWMgrBxG3Meta || {},
    alias: indexjSWMgrBxG3Meta?.alias || [],
    redirect: indexjSWMgrBxG3Meta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexmPVMD47iixMeta?.name ?? "account-ledenvoordelen",
    path: indexmPVMD47iixMeta?.path ?? "ledenvoordelen",
    meta: indexmPVMD47iixMeta || {},
    alias: indexmPVMD47iixMeta?.alias || [],
    redirect: indexmPVMD47iixMeta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/account/ledenvoordelen/index.vue").then(m => m.default || m)
  },
  {
    name: incassoW6fsjQtHpjMeta?.name ?? "account-lidmaatschap-lidmaatschap-incasso",
    path: incassoW6fsjQtHpjMeta?.path ?? "lidmaatschap/:lidmaatschap()/incasso",
    meta: incassoW6fsjQtHpjMeta || {},
    alias: incassoW6fsjQtHpjMeta?.alias || [],
    redirect: incassoW6fsjQtHpjMeta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/account/lidmaatschap/[lidmaatschap]/incasso.vue").then(m => m.default || m)
  },
  {
    name: indexoDpxQIuSL7Meta?.name ?? "account-lidmaatschap-lidmaatschap",
    path: indexoDpxQIuSL7Meta?.path ?? "lidmaatschap/:lidmaatschap()",
    meta: indexoDpxQIuSL7Meta || {},
    alias: indexoDpxQIuSL7Meta?.alias || [],
    redirect: indexoDpxQIuSL7Meta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/account/lidmaatschap/[lidmaatschap]/index.vue").then(m => m.default || m)
  },
  {
    name: index0B5ZbhN9eMMeta?.name ?? "account-lidmaatschap",
    path: index0B5ZbhN9eMMeta?.path ?? "lidmaatschap",
    meta: index0B5ZbhN9eMMeta || {},
    alias: index0B5ZbhN9eMMeta?.alias || [],
    redirect: index0B5ZbhN9eMMeta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/account/lidmaatschap/index.vue").then(m => m.default || m)
  },
  {
    name: nieuwsbriefvoorkeurenn6s52pwezLMeta?.name ?? "account-nieuwsbriefvoorkeuren",
    path: nieuwsbriefvoorkeurenn6s52pwezLMeta?.path ?? "nieuwsbriefvoorkeuren",
    meta: nieuwsbriefvoorkeurenn6s52pwezLMeta || {},
    alias: nieuwsbriefvoorkeurenn6s52pwezLMeta?.alias || [],
    redirect: nieuwsbriefvoorkeurenn6s52pwezLMeta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/account/nieuwsbriefvoorkeuren.vue").then(m => m.default || m)
  }
]
  },
  {
    name: loginDoC8s7eoGZMeta?.name ?? "auth-login",
    path: loginDoC8s7eoGZMeta?.path ?? "/auth/login",
    meta: loginDoC8s7eoGZMeta || {},
    alias: loginDoC8s7eoGZMeta?.alias || [],
    redirect: loginDoC8s7eoGZMeta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: _91token_93Himk3SoqZNMeta?.name ?? "auth-wachtwoord-instellen-token",
    path: _91token_93Himk3SoqZNMeta?.path ?? "/auth/wachtwoord-instellen/:token()",
    meta: _91token_93Himk3SoqZNMeta || {},
    alias: _91token_93Himk3SoqZNMeta?.alias || [],
    redirect: _91token_93Himk3SoqZNMeta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/auth/wachtwoord-instellen/[token].vue").then(m => m.default || m)
  },
  {
    name: _91token_93UT7ggJAbW4Meta?.name ?? "auth-wachtwoord-vergeten-token",
    path: _91token_93UT7ggJAbW4Meta?.path ?? "/auth/wachtwoord-vergeten/:token()",
    meta: _91token_93UT7ggJAbW4Meta || {},
    alias: _91token_93UT7ggJAbW4Meta?.alias || [],
    redirect: _91token_93UT7ggJAbW4Meta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/auth/wachtwoord-vergeten/[token].vue").then(m => m.default || m)
  },
  {
    name: indexgsm2b4DDyUMeta?.name ?? "auth-wachtwoord-vergeten",
    path: indexgsm2b4DDyUMeta?.path ?? "/auth/wachtwoord-vergeten",
    meta: indexgsm2b4DDyUMeta || {},
    alias: indexgsm2b4DDyUMeta?.alias || [],
    redirect: indexgsm2b4DDyUMeta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/auth/wachtwoord-vergeten/index.vue").then(m => m.default || m)
  },
  {
    name: bestuurdersFXuF847FgCMeta?.name ?? undefined,
    path: bestuurdersFXuF847FgCMeta?.path ?? "/bestuurders",
    meta: bestuurdersFXuF847FgCMeta || {},
    alias: bestuurdersFXuF847FgCMeta?.alias || [],
    redirect: bestuurdersFXuF847FgCMeta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/bestuurders.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_930CO2jsJgUAMeta?.name ?? "bestuurders-slug",
    path: _91slug_930CO2jsJgUAMeta?.path ?? ":slug()",
    meta: _91slug_930CO2jsJgUAMeta || {},
    alias: _91slug_930CO2jsJgUAMeta?.alias || [],
    redirect: _91slug_930CO2jsJgUAMeta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/bestuurders/[slug].vue").then(m => m.default || m)
  },
  {
    name: bestuurdersacademieFFrxpww6jbMeta?.name ?? "bestuurders-bestuurdersacademie",
    path: bestuurdersacademieFFrxpww6jbMeta?.path ?? "bestuurdersacademie",
    meta: bestuurdersacademieFFrxpww6jbMeta || {},
    alias: bestuurdersacademieFFrxpww6jbMeta?.alias || [],
    redirect: bestuurdersacademieFFrxpww6jbMeta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/bestuurders/bestuurdersacademie.vue").then(m => m.default || m)
  },
  {
    name: dossiers_45documentengJ9krHYAK4Meta?.name ?? "bestuurders-dossiers-documenten",
    path: dossiers_45documentengJ9krHYAK4Meta?.path ?? "dossiers-documenten",
    meta: dossiers_45documentengJ9krHYAK4Meta || {},
    alias: dossiers_45documentengJ9krHYAK4Meta?.alias || [],
    redirect: dossiers_45documentengJ9krHYAK4Meta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/bestuurders/dossiers-documenten.vue").then(m => m.default || m)
  },
  {
    name: indexvhPZKUZqwhMeta?.name ?? "bestuurders",
    path: indexvhPZKUZqwhMeta?.path ?? "",
    meta: indexvhPZKUZqwhMeta || {},
    alias: indexvhPZKUZqwhMeta?.alias || [],
    redirect: indexvhPZKUZqwhMeta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/bestuurders/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: dashboardgZH5adsuxCMeta?.name ?? "dashboard",
    path: dashboardgZH5adsuxCMeta?.path ?? "/dashboard",
    meta: dashboardgZH5adsuxCMeta || {},
    alias: dashboardgZH5adsuxCMeta?.alias || [],
    redirect: dashboardgZH5adsuxCMeta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: evenementen0d7WBqv60EMeta?.name ?? undefined,
    path: evenementen0d7WBqv60EMeta?.path ?? "/evenementen",
    meta: evenementen0d7WBqv60EMeta || {},
    alias: evenementen0d7WBqv60EMeta?.alias || [],
    redirect: evenementen0d7WBqv60EMeta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/evenementen.vue").then(m => m.default || m),
    children: [
  {
    name: aanmelden4ST5AsBRaGMeta?.name ?? "evenementen-slug-aanmelden",
    path: aanmelden4ST5AsBRaGMeta?.path ?? ":slug()/aanmelden",
    meta: aanmelden4ST5AsBRaGMeta || {},
    alias: aanmelden4ST5AsBRaGMeta?.alias || [],
    redirect: aanmelden4ST5AsBRaGMeta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/evenementen/[slug]/aanmelden.vue").then(m => m.default || m)
  },
  {
    name: index7v0F4isNYaMeta?.name ?? "evenementen-slug",
    path: index7v0F4isNYaMeta?.path ?? ":slug()",
    meta: index7v0F4isNYaMeta || {},
    alias: index7v0F4isNYaMeta?.alias || [],
    redirect: index7v0F4isNYaMeta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/evenementen/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexs8BXff73KEMeta?.name ?? "evenementen",
    path: indexs8BXff73KEMeta?.path ?? "",
    meta: indexs8BXff73KEMeta || {},
    alias: indexs8BXff73KEMeta?.alias || [],
    redirect: indexs8BXff73KEMeta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/evenementen/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: index3QodGLAezDMeta?.name ?? "index",
    path: index3QodGLAezDMeta?.path ?? "/",
    meta: index3QodGLAezDMeta || {},
    alias: index3QodGLAezDMeta?.alias || [],
    redirect: index3QodGLAezDMeta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/index.vue").then(m => m.default || m)
  },
  {
    name: kennisbankpbfSTPGB32Meta?.name ?? "kennisbank",
    path: kennisbankpbfSTPGB32Meta?.path ?? "/kennisbank",
    meta: kennisbankpbfSTPGB32Meta || {},
    alias: kennisbankpbfSTPGB32Meta?.alias || [],
    redirect: kennisbankpbfSTPGB32Meta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/kennisbank.vue").then(m => m.default || m)
  },
  {
    name: _91id_93A4xJaIcomBMeta?.name ?? "klanten-id",
    path: _91id_93A4xJaIcomBMeta?.path ?? "/klanten/:id()",
    meta: _91id_93A4xJaIcomBMeta || {},
    alias: _91id_93A4xJaIcomBMeta?.alias || [],
    redirect: _91id_93A4xJaIcomBMeta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/klanten/[id].vue").then(m => m.default || m)
  },
  {
    name: indexAWJV5Shm8VMeta?.name ?? "klanten",
    path: indexAWJV5Shm8VMeta?.path ?? "/klanten",
    meta: indexAWJV5Shm8VMeta || {},
    alias: indexAWJV5Shm8VMeta?.alias || [],
    redirect: indexAWJV5Shm8VMeta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/klanten/index.vue").then(m => m.default || m)
  },
  {
    name: ledenvoordelendG0aQyZf1nMeta?.name ?? undefined,
    path: ledenvoordelendG0aQyZf1nMeta?.path ?? "/ledenvoordelen",
    meta: ledenvoordelendG0aQyZf1nMeta || {},
    alias: ledenvoordelendG0aQyZf1nMeta?.alias || [],
    redirect: ledenvoordelendG0aQyZf1nMeta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/ledenvoordelen.vue").then(m => m.default || m),
    children: [
  {
    name: afnemenm9ymMoPVMZMeta?.name ?? "ledenvoordelen-slug-afnemen",
    path: afnemenm9ymMoPVMZMeta?.path ?? ":slug()/afnemen",
    meta: afnemenm9ymMoPVMZMeta || {},
    alias: afnemenm9ymMoPVMZMeta?.alias || [],
    redirect: afnemenm9ymMoPVMZMeta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/ledenvoordelen/[slug]/afnemen.vue").then(m => m.default || m)
  },
  {
    name: indexQ67XLr64nUMeta?.name ?? "ledenvoordelen-slug",
    path: indexQ67XLr64nUMeta?.path ?? ":slug()",
    meta: indexQ67XLr64nUMeta || {},
    alias: indexQ67XLr64nUMeta?.alias || [],
    redirect: indexQ67XLr64nUMeta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/ledenvoordelen/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexgEhxOM47FjMeta?.name ?? "ledenvoordelen",
    path: indexgEhxOM47FjMeta?.path ?? "",
    meta: indexgEhxOM47FjMeta || {},
    alias: indexgEhxOM47FjMeta?.alias || [],
    redirect: indexgEhxOM47FjMeta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/ledenvoordelen/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexaW81OkGjjcMeta?.name ?? "lid-worden",
    path: indexaW81OkGjjcMeta?.path ?? "/lid-worden",
    meta: indexaW81OkGjjcMeta || {},
    alias: indexaW81OkGjjcMeta?.alias || [],
    redirect: indexaW81OkGjjcMeta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/lid-worden/index.vue").then(m => m.default || m)
  },
  {
    name: uitschrijvenB9O72LDJCyMeta?.name ?? "nieuwsbrief-uitschrijven",
    path: uitschrijvenB9O72LDJCyMeta?.path ?? "/nieuwsbrief/uitschrijven",
    meta: uitschrijvenB9O72LDJCyMeta || {},
    alias: uitschrijvenB9O72LDJCyMeta?.alias || [],
    redirect: uitschrijvenB9O72LDJCyMeta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/nieuwsbrief/uitschrijven.vue").then(m => m.default || m)
  },
  {
    name: authorizeFdQkNoWIzcMeta?.name ?? "oauth-authorize",
    path: authorizeFdQkNoWIzcMeta?.path ?? "/oauth/authorize",
    meta: authorizeFdQkNoWIzcMeta || {},
    alias: authorizeFdQkNoWIzcMeta?.alias || [],
    redirect: authorizeFdQkNoWIzcMeta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/oauth/authorize.vue").then(m => m.default || m)
  },
  {
    name: styles8CMSEP4B2HMeta?.name ?? "styles",
    path: styles8CMSEP4B2HMeta?.path ?? "/styles",
    meta: styles8CMSEP4B2HMeta || {},
    alias: styles8CMSEP4B2HMeta?.alias || [],
    redirect: styles8CMSEP4B2HMeta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/styles.vue").then(m => m.default || m)
  },
  {
    name: _91id_93pMjUchKX2ZMeta?.name ?? "verkoopkansen-id",
    path: _91id_93pMjUchKX2ZMeta?.path ?? "/verkoopkansen/:id()",
    meta: _91id_93pMjUchKX2ZMeta || {},
    alias: _91id_93pMjUchKX2ZMeta?.alias || [],
    redirect: _91id_93pMjUchKX2ZMeta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/verkoopkansen/[id].vue").then(m => m.default || m)
  },
  {
    name: indexXt69ed4WjoMeta?.name ?? "verkoopkansen",
    path: indexXt69ed4WjoMeta?.path ?? "/verkoopkansen",
    meta: indexXt69ed4WjoMeta || {},
    alias: indexXt69ed4WjoMeta?.alias || [],
    redirect: indexXt69ed4WjoMeta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/verkoopkansen/index.vue").then(m => m.default || m)
  },
  {
    name: webshop72RRcROG7JMeta?.name ?? undefined,
    path: webshop72RRcROG7JMeta?.path ?? "/webshop",
    meta: webshop72RRcROG7JMeta || {},
    alias: webshop72RRcROG7JMeta?.alias || [],
    redirect: webshop72RRcROG7JMeta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/webshop.vue").then(m => m.default || m),
    children: [
  {
    name: _91product_93FIoQC3vjlAMeta?.name ?? "webshop-category-product",
    path: _91product_93FIoQC3vjlAMeta?.path ?? ":category()/:product()",
    meta: _91product_93FIoQC3vjlAMeta || {},
    alias: _91product_93FIoQC3vjlAMeta?.alias || [],
    redirect: _91product_93FIoQC3vjlAMeta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/webshop/[category]/[product].vue").then(m => m.default || m)
  },
  {
    name: indexZooA0134GGMeta?.name ?? "webshop-category",
    path: indexZooA0134GGMeta?.path ?? ":category()",
    meta: indexZooA0134GGMeta || {},
    alias: indexZooA0134GGMeta?.alias || [],
    redirect: indexZooA0134GGMeta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/webshop/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: cartfTWngYDExOMeta?.name ?? "webshop-cart",
    path: cartfTWngYDExOMeta?.path ?? "cart",
    meta: cartfTWngYDExOMeta || {},
    alias: cartfTWngYDExOMeta?.alias || [],
    redirect: cartfTWngYDExOMeta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/webshop/cart.vue").then(m => m.default || m)
  },
  {
    name: checkout6eou6RV1dtMeta?.name ?? "webshop-checkout",
    path: checkout6eou6RV1dtMeta?.path ?? "checkout",
    meta: checkout6eou6RV1dtMeta || {},
    alias: checkout6eou6RV1dtMeta?.alias || [],
    redirect: checkout6eou6RV1dtMeta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/webshop/checkout.vue").then(m => m.default || m)
  },
  {
    name: confirmationP1vsFCKG9kMeta?.name ?? "webshop-confirmation",
    path: confirmationP1vsFCKG9kMeta?.path ?? "confirmation",
    meta: confirmationP1vsFCKG9kMeta || {},
    alias: confirmationP1vsFCKG9kMeta?.alias || [],
    redirect: confirmationP1vsFCKG9kMeta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/webshop/confirmation.vue").then(m => m.default || m)
  },
  {
    name: indexX6ANzwotymMeta?.name ?? "webshop",
    path: indexX6ANzwotymMeta?.path ?? "",
    meta: indexX6ANzwotymMeta || {},
    alias: indexX6ANzwotymMeta?.alias || [],
    redirect: indexX6ANzwotymMeta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/webshop/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: zoekenhqv1Q5LnugMeta?.name ?? "zoeken",
    path: zoekenhqv1Q5LnugMeta?.path ?? "/zoeken",
    meta: zoekenhqv1Q5LnugMeta || {},
    alias: zoekenhqv1Q5LnugMeta?.alias || [],
    redirect: zoekenhqv1Q5LnugMeta?.redirect,
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/35/nuxt/pages/zoeken.vue").then(m => m.default || m)
  },
  {
    name: indexD704OlaQYNMeta?.name ?? "bestuurders-evenementen-slug",
    path: indexD704OlaQYNMeta?.path ?? "/bestuurders/evenementen/:slug()",
    meta: indexD704OlaQYNMeta || {},
    alias: indexD704OlaQYNMeta?.alias || [],
    redirect: indexD704OlaQYNMeta?.redirect,
    component: () => import("~/pages/evenementen/[slug]/index.vue").then(m => m.default || m)
  }
]